<template>
  <vue-context-menu id="context-menu" ref="ctxMenu">
    <li
      class="ctx-item"
      @click.stop.prevent="menuItemSelected(item)"
      v-for="item in getContextMenuItems"
      :key="item.code"
    >
      {{ item.label }}
    </li>
  </vue-context-menu>
</template>

<script>
import EventBus from "./EventBus";
import VueContextMenu from "vue-context-menu";

export default {
  components: {
    VueContextMenu,
  },
  props: {
    contextMenuItems: {
      type: Array,
    },
  },
  data() {
    return {
      activeNode: null,
    };
  },
  methods: {
    open(node) {
      this.activeNode = node;
      this.$refs.ctxMenu.open();
    },
    menuItemSelected(item) {
      EventBus.$emit("contextMenuItemSelect", item, this.activeNode);
    },
  },
  computed: {
    getContextMenuItems() {
      const node = this.activeNode;
      let menuItems = this.contextMenuItems;
      if (node && node.data && !node.data.new) {
        menuItems = menuItems.filter((item) => item.code !== "RENAME_NODE");
      }
      return menuItems;
    },
  },
  created() {
    EventBus.$on("openNodeContextMenu", this.open);
  },
};
</script>

<style>
.ctx-item {
  cursor: pointer;
  user-select: none;
}
</style>
